<template>

  <img alt="Vue logo" src="./assets/logo.jpg">
  <Title msg="Welcome to Liars Anonymous"/>
  <Preamble />
  <Meetings/>
  <ZoomFormater/>
  <Tools />
</template>

<script>
import Title from './components/Title.vue'
import Preamble from './components/Preamble.vue'
import Meetings from './components/Meetings.vue'
import Tools from './components/Tools.vue'



export default {
  name: 'App',
  components: {
    Title,
    Preamble,
    Meetings,
    Tools
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
