<template>
  <div v-show="false" class="container">

      <button 
    class="initial " 
    v-on:click="myFilter"
    v-bind:class="{ active: isActive }"
    ></button>
   <!--<div v-if="isActive">Content testing</div> -->
  <span v-show="isActive" class="wkday">M</span>
    <button 
    @click="this.isActive = !this.isActive"
    >Click me</button>

    <div><h2>List of Tools</h2></div>
    
    <div v-show="isActive">
    <div>Connecting with others</div>
    <div>Writing</div>
    <div>Action Plan</div>
    <div>Prayer and Meditaiton</div>
    <div>Meetings</div>
    </div>
    
  </div>

</template>
<script>
export default {
  name: 'Tools',
  props: {
    msg: String
  },
  data () {
      //let isActive = true
      return {isActive:true }
  },
  methods: {
  myFilter: function() {
      this.isActive = !this.isActive;
      // some code to filter users
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.item {
  margin-right: 10%;
  margin-left: 10%;
}

</style>
