<template>
  <div class="container">

    <div><h2>About</h2></div>

    <div class="item"><p>Liars Anonymous is a fellowship of people who share their experience, strength and hope with each other that they may solve their common problem and help others to recover from dishonest tendencies.
    </p></div>
    
    <div class="item"><p>The only requirement for membership is a desire to stop being dishonest. There are no dues or fees for L.A. membership; we are self- supporting through our own contributions. L.A. is not allied with any sect, denomination, politics, organization or institution; does not wish to engage in any controversy, neither endorses nor opposes any causes. Our primary purpose is to get honest, stay honest and help others do the same.
    </p></div>

    <div class="item"><p>If you are having problems with lying  and honesty and think you may be a compulsive liar, you have come to the right place. Liars Anonymous is here to help you. Many of us have come here out of crisis, a crisis that our dishonesty contributed towards. Either our family, personal relationship, work relationships or friendships were in shambles. We came here to get help. You are not alone and there is hope. Welcome.</p></div>


    <div class="item"><p>We are a rather new and small fellowship, as our first meeting was Dec 12, 2021.
    </p></div>

  </div>

</template>
<script>
export default {
  name: 'Preamble',
  props: {
    msg: String
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.item {
  margin-right: 10%;
  margin-left: 10%;
}

</style>
