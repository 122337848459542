<template>
  <div>
    <!-- <v-data-table  :items="items"> wtf</v-data-table> -->
<!-- <div style="width: autop; margin-length: auto;">
    <table>
      <thead>
        <tr>
          <th>Day</th>
          <th>Time</th>
          <th>Type</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="meeting in meetings" :key="meeting.id">
          <td>{{ meeting.name }}</td>
          <td>{{ meeting.time }}</td>
          <td>{{ meeting.Type }}</td>
          
        </tr>
      </tbody>
    </table>
  </div> -->
<h2>Meetings</h2>
<br><b>Monday</b>    at  5:30pm(PST)/8:30pm(EST)   (Discussion meeting)       <br>
<br><b>Wednesday</b> at  5:30pm(PST)/8:30pm(EST)   (Step Study)       <br>
<br><b>Thursday</b>  at  4:30pm(PST)/7:30pm(EST)   (Meditation and  Daily Inventory)  <br>
<br><b>Thursday</b>  at  5:30pm(PST)/8:30pm(EST)   (SPECIAL MEETING: Support group for the liar's loved ones, proccessing being lied to)<br>
<br><b>Saturday</b>  at  7:00am(PST)/10:00am(EST)  (Informal Discussion)<br>
<br><b>Sunday</b>    at  5:30pm(PST)/8:30pm(EST)   (Step study)<br>

<br>
<br>Zoom Link for all Meetings/Workshops: 
<br><a href="https://us02web.zoom.us/j/8254751619?pwd=ajVtMm1hVFFjeWsrb2VtVFhYTUZ1dz09">https://us02web.zoom.us/j/8254751619?pwd=ajVtMm1hVFFjeWsrb2VtVFhYTUZ1dz09</a>

<!-- 
Meeting ID: 825 475 1619
Passcode: 6wSQRe
One tap mobile
+16699006833,,8254751619# US (San Jose)
+13462487799,,8254751619# US (Houston)

Dial by your location
        +1 669 900 6833 US (San Jose)
        +1 346 248 7799 US (Houston)
        +1 253 215 8782 US (Tacoma)
        +1 301 715 8592 US (Washington DC)
        +1 312 626 6799 US (Chicago)
        +1 929 205 6099 US (New York)
Meeting ID: 825 475 1619
Find your local number: https://us02web.zoom.us/u/kclis1gRbY -->

<br><br>
<br><br>For contact information or to be added to the weekly announcements email list:<br><br>
<b>liars.anonymous.fellowship@gmail.com</b>

</div>
</template>
<script>
export default {
  name: 'Meetings',
  props: {
    msg: String 
  },
  data () { return {
          items : [          
            { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
            { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
            { age: 89, first_name: 'Geneva', last_name: 'Wilson' },
            { age: 38, first_name: 'Jami', last_name: 'Carney' }],
          meetings: [
                { id: 1, name: 'Monday',   time: '5:30pm(PST)/8:30pm(EST)', Type: 'Discussion Meeting' },
                { id: 2, name: 'Wednesday',time: '5:30pm(PST)/8:30pm(EST)', Type: 'Step Study: Homegroup' },
                { id: 3, name: 'Thursday', time: '4:30pm(PST)/7:30pm(EST)', Type: 'Meditation/Inventory/Discussion Meeting' },
                { id: 4, name: 'Thursday', time: '5:30pm(PST)/8:30pm(EST)', Type: 'SPECIAL MEETING: Support group for the liar\'s loved ones, proccessing being lied to' },
                { id: 5, name: 'Saturday', time: '7:00am(PST)/10:00am(EST)', Type: 'New Discussion meeting' }
              ],
        }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
